var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.loaded)?_c('div',{staticClass:"create_preview"},[_c('div',{staticClass:"preview_tip"},[(
        _vm.previewNo.groupOneNameList.length > 0 ||
        _vm.previewNo.groupTwoNameList.length > 0
      )?[_c('h5',{staticClass:"tip_h5"},[_c('span',{staticClass:"tip_icon"}),_vm._v(_vm._s(_vm.$t("cases.createPreview.wwcts"))+" ")]),_c('ul',{staticClass:"p_tip_ul"},[(_vm.previewNo.groupOneNameList.length > 0)?_c('li',{staticClass:"p_tip_li"},[_c('h5',{staticClass:"p_tip_l_h"},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.jzzl"))+" ")]),_c('div',{staticClass:"p_tip_l_box"},_vm._l((_vm.previewNo.groupOneNameList),function(item){return _c('span',{key:((_vm.$t('cases.createPreview.jzzl')) + "_" + item),on:{"click":function($event){return _vm.groupClick(item, 1)}}},[_vm._v(_vm._s(item))])}),0)]):_vm._e(),(_vm.previewNo.groupTwoNameList.length > 0)?_c('li',{staticClass:"p_tip_li"},[_c('h5',{staticClass:"p_tip_l_h"},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.yxzlsm"))+" ")]),_c('div',{staticClass:"p_tip_l_box"},_vm._l((_vm.previewNo.groupTwoNameList),function(item){return _c('span',{key:((_vm.$t('cases.createPreview.yxzlsm')) + "_" + item),attrs:{"data-tit":item,"data-type":"2"},on:{"click":function($event){return _vm.groupClick(item, 2)}}},[_vm._v(_vm._s(item))])}),0)]):_vm._e()])]:_vm._e(),(!_vm.queer)?[_c('h2',{staticClass:"tip_h5"},[_c('span',{staticClass:"tip_icon"}),_vm._v(_vm._s(_vm.$t("cases.createPreview.wwcts"))+" ")]),_c('div',{staticClass:"queer"},[_c('span',{staticClass:"mr10",on:{"click":function($event){return _vm.goBackQueer('1')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.jzzl"))+" ")]),_c('span',{on:{"click":function($event){return _vm.goBackQueer('2')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.yxzlsm"))+" ")])])]:_vm._e()],2),(
      _vm.previewNo.groupOneNameList.length < 1 &&
      _vm.previewNo.groupTwoNameList.length < 1 &&
      _vm.queer
    )?_c('div',{staticClass:"preview_box"},[_c('h5',{staticClass:"tip_h5"},[_c('span',{staticClass:"tip_icon tip_icon_ok"}),_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.bltxwz"))+" ")]),_c('div',{staticClass:"preview_box_content"},[_c('div',{staticClass:"flex-y-c mt2rem mb2rem"},[_c('p',{staticClass:"to_clinical",on:{"click":function($event){$event.stopPropagation();return _vm.goClinical($event)}}},[_vm._v(" "+_vm._s(_vm.$t("common.lcph.cklcph"))+" ")]),_c('p',{staticClass:"fz14 main_theme_color_999"},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.ggphtext"))+" ")])]),_c('ul',{staticClass:"preview_box_ul"},[_c('public-cases-user',{attrs:{"userInfo":_vm.userInfo,"caseInfo":_vm.caseDetail}}),_c('PreviewDiagnosis',{key:1,attrs:{"commonTeeth":_vm.commonTeeth,"uploadIntervalList":_vm.uploadIntervalList,"veneerList":_vm.commonVeneerList,"crowdTeethList":_vm.crowdTeethList,"productType":_vm.routeQueryType,"casesData":_vm.casesData,"details":_vm.details}}),_c('public-cases-pic',{attrs:{"surfacePicList":_vm.routeQueryType === 'F' ? _vm.yueyaPicList : _vm.surfacePicList,"xPicList":_vm.xPicList,"details":_vm.details}})],1)])]):_vm._e(),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1):_c('div',[_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}]})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }